import React from 'react'
import useMedia from 'use-media'

import Layout from '../layout'
import PageNotFoundIcon from '../assets/icons/page-not-found.svg'

const NotFound = () => {
  const isSmall = useMedia({ maxWidth: 650 })
  return (
    <Layout>
      <section
        className={`o-container ${
          isSmall ? 'u-Pt-md' : 'u-Pt-xl'
        } u-Mb-xl u-flex u-flex-col u-items-center`}
      >
        <div
          className="u-relative u-mb-md"
          style={{ transform: isSmall ? 'scale(0.7)' : '' }}
        >
          <PageNotFoundIcon />
          <h1
            className="u-absolute u-text-livechat"
            style={{ top: '150px', left: '100px', fontSize: '84px' }}
          >
            404
          </h1>
        </div>
        <h2 className="u-text-p2">Page not Found</h2>
        <div>
          <h3 className="u-text-p6">
            You can report this problem to our support team:
          </h3>
          <ul>
            <li>
              <a href="/">start a chat</a>
            </li>
            <li>
              email us:{' '}
              <a
                href="mailto:support@livechatinc.com"
                alt="mail developer program"
              >
                support@livechatinc.com
              </a>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default NotFound
